import {
Link
} from "react-router-dom";
const Header=()=>{
    return(
        <>
<div class="container">
<nav class="navbar navbar-expand-lg navbar-light ">
  <div class="container-fluid">
     <Link to="/" class="navbar-brand" href="#">
    <img src="images/logo.png" class="logo" />
    </Link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    

    <div class="collapse navbar-collapse" id="navbarScroll">
      
<div>
      <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={{'--bs-scroll-height': '100px'}}>
        <li class="nav-item">
          <Link to="/" class="nav-link active" aria-current="page">Home</Link>
        </li>
        <li class="nav-item">
        <Link to="/about" class="nav-link" href="#">About</Link>
        </li>
        <li class="nav-item">
           <Link to="/services" class="nav-link dropdown-toggle"  aria-expanded="false">
            Services
          </Link>
          {/* <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
            <li><a class="dropdown-item" href="#">Retaurant</a></li>
            <li><a class="dropdown-item" href="#">Taxi To Airport</a></li>
            <li><a class="dropdown-item" href="#">Lounge</a></li>
            <li><hr class="dropdown-divider" /></li>
           
            <li><a class="dropdown-item" href="#">Cofee</a></li>
            <li><a class="dropdown-item" href="#">Rooms</a></li>
            <li><a class="dropdown-item" href="#">Wedding Halls</a></li>
          </ul> */}
        </li>
        <li class="nav-item">
        <Link to="/travel" class="nav-link " href="#" tabindex="-1">Travel</Link>
        </li>

         <li class="nav-item">
         <Link to="/restaurant" class="nav-link " tabindex="-1" >Restaurant</Link>
        </li>

        <li class="nav-item">
        <Link to="/contact" class="nav-link active" aria-current="page">Contact</Link>
        </li>
      </ul>

</div>

<div class="social_box">
  <ul>
  <li><i class="fa fa-facebook"></i></li>
  <li><i class="fa fa-instagram"></i></li>
  <li><i class="fa fa-twitter"></i></li>
  <li><i class="fa fa-whatsapp"></i></li>
  </ul>
</div>
  </div>
  </div>
</nav>
</div>
  <div class="menu_line"></div>
  </>
    )
}
export default Header;