const Travel=()=>{
    return(
        <>


<section class="container-fluid inner_header">
       <h1 class="inner_heading_text">Travel Informatiion</h1>
</section>

<section class="container">


<div class="row">
    <div class="col-md-9 left_inner_content">

<img src="images/left_article_image.jpg" style={{float:'left'}} class="img-fluid" />
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
     Lorem Ipsum has been the industry's standard dummy text ever
      since the 1500s, when an unknown printer took a galley of type
       and scrambled it to make a type specimen book. It has survived 
       not only five centuries, but also the leap into electronic typesetting, 
       remaining essentially unchanged. It was popularised in the 1960s with
        the release of Letraset sheets containing Lorem Ipsum passages, 
        and more recently with desktop publishing software like Aldus 
        PageMaker including versions of Lorem Ipsum.type specimen book.
         It has survived not only five centuries, but also the leap into 
         electronic typesetting, remaining essentially unchanged. It was 
         popularised in the 1960s with the release of Letraset sheets containing
          Lorem Ipsum passages, and more recently with desktop publishing software
           like Aldus PageMaker including versions of Lorem Ipsum.
           Lorem Ipsum is simply dummy text of the printing and typesetting industry.
           Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type
             and scrambled it to make a type specimen book. It has survived 
             not only five centuries, but also the leap into electronic typesetting, 
             remaining essentially unchanged. It was popularised in the 1960s with
              the release of Letraset sheets containing Lorem Ipsum passages, 
              and more recently with desktop publishing software like Aldus 
              PageMaker including versions of Lorem Ipsum.type specimen book.
               It has survived not only five centuries, but also the leap into 
               electronic typesetting, remaining essentially unchanged. It was 
               popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software
                 like Aldus PageMaker including versions of Lorem Ipsum.    
        
        
        </p>
          
<div class="row form_box">
<div class="col-md-12"> Comment</div>
<div class="col-md-12"><input type="text" name="full_name"  class="form-control" placeholder="Enter Your Full name" /></div>
<div class="col-md-12"><input type="email" name="email"  class="form-control" placeholder="Enter Your Email Id" /></div>
<div class="col-md-12">
    <textarea class="form-control" placeholder="Enter your comment"></textarea>
</div>
<div class="col-md-12 text-center"><button class="btn btn-success btn-comment">Post Your Comment</button></div>
</div>


    </div>
    <div class="col-md-3 text-center sidebar_main"> 
        <div class="sidebar_heading">Latest Post</div>
    

    <div class="row sidebar_box">
        <div class="col-md-3"><img src="images/banner_left_cake.png" class="img-fluid" /></div>
        <div class="col-md-9 text-left">
            <h3>Enjoy tasty foods at low cost</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and </p>
        </div>
    </div>

    <div class="row sidebar_box">
        <div class="col-md-3"><img src="images/banner_left_cake.png" class="img-fluid" /></div>
        <div class="col-md-9 text-left">
            <h3>Enjoy tasty foods at low cost</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and </p>
        </div>
    </div> 


    <div class="sidebar_heading">Categories</div>

    <div class="sidebar_category">
    <ul>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Food</li>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Restaurant</li>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Other</li>
    </ul>

    </div>



</div>
</div>
</section>
</>
)
}

export default Travel;
