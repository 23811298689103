import {
    Link
  } from "react-router-dom";
const ServiceList=()=>{
    return(
        <>
<div class="row">
<div class="col-md-4">
  <div class="fa fa-cutlery text-center col-md-12" aria-hidden="true"></div>
<h3 class="text-center service_heading"><Link to='/restaurant' className="service_heading">Restaurant</Link></h3>
<p>We have nice restaurant where you can get variety of foods with low cost price.</p>
</div>
<div class="col-md-4">
  <div class="fa fa-taxi text-center col-md-12" aria-hidden="true"></div>
  <h3 class="text-center service_heading"><Link to='/taxi' className="service_heading">Taxi To Airport</Link></h3>
  <p>We have nice restaurant where you can get variety of foods with low cost price.</p>

</div>
<div class="col-md-4">
  <div class="fa fa-taxi text-center col-md-12" aria-hidden="true"></div>
  <h3 class="text-center service_heading"><Link to='/lounge' className="service_heading">Lounge</Link></h3>
  <p>We have lush and greenery lounge where you can sit and rest and refresh yourself.</p>

</div>
</div>



<div class="row">
<div class="col-md-4">
  <div class="fa fa-coffee text-center col-md-12" aria-hidden="true"></div>
  <h3 class="text-center service_heading"><Link to='/cofee' className="service_heading">Cofee</Link></h3>
<p>We have Taxi for which we can provide you facility to pickup and drop to and from Airport.</p>
</div>
<div class="col-md-4">
  
  <div class="fa fa-hotel text-center col-md-12" aria-hidden="true"></div>
  <h3 class="text-center service_heading"><Link to='/rooms' className="service_heading">Rooms</Link></h3>
  <p>We have family rooms for our guest. If you want book our rooms you can contact us  any time</p>

</div>
<div class="col-md-4">
  <div class="fa fa fa-life-ring text-center col-md-12" aria-hidden="true"></div>

  <h3 class="text-center service_heading"><Link to='/wedding' className="service_heading">Wedding Hall</Link></h3>
  <p>We have fully decorated wedding hall for marriage. You can book our wedding hall by calling us.</p>

</div>
</div>
</>
    )
}

export default ServiceList;