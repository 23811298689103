const Restaurant=()=>{
    return(
<>
<section class="container-fluid inner_header">
       <h1 class="inner_heading_text">Restaurant</h1>
</section>

<section class="container">


<div class="row">
    <div class="col-md-9 left_inner_content">
        <div>
    <img src="images/banner_left_cake.png" class="img-fluid" style={{float:'left',
    paddingRight: '10px'}} />
       <p>
 
         Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
         Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        </p>
    </div>
    </div>

    <div class="col-md-3 text-center sidebar_main"> 
        <div class="sidebar_heading">Latest Post</div>
    

    <div class="row sidebar_box">
        <div class="col-md-3"><img src="images/banner_left_cake.png" class="img-fluid" /></div>
        <div class="col-md-9 text-left">
            <h3>Enjoy tasty foods at low cost</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and </p>
        </div>
    </div>

    <div class="row sidebar_box">
        <div class="col-md-3"><img src="images/banner_left_cake.png" class="img-fluid" /></div>
        <div class="col-md-9 text-left">
            <h3>Enjoy tasty foods at low cost</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and </p>
        </div>
    </div> 


    <div class="sidebar_heading">Categories</div>

    <div class="sidebar_category">
    <ul>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Food</li>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Restaurant</li>
        <li><span class="fa fa-angle-double-right" aria-hidden="true"></span> Other</li>
    </ul>

    </div>



</div>
</div>
</section>

</>

)
}
export default Restaurant;