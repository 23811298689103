import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Link
} from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import ServiceList from './Components/ServiceList';
import Restaurant from './Components/services/Restaurant';
import Taxi from './Components/services/Taxi';
import Cofee from './Components/services/Cofee' ;
import Wedding from './Components/services/Wedding';
import Lounge from './Components/services/Lounge';
import Rooms from './Components/services/Rooms';
import Contact from './Components/Contact';
import Travel from './Components/Travel';
function App() {
  return (
  <BrowserRouter >
   
  <Header/>
      <Routes>    
              <Route path="/" element={<Home/>} /> 
              <Route path="/about" element={<About/>} /> 
              
        <Route path="/services" element={<Services/>} >
            <Route path="/services" element={<ServiceList />} />
        </Route>

        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/taxi" element={<Taxi />} />
        <Route path="/cofee" element={<Cofee />} />
        <Route path="/wedding" element={<Wedding />} />
        <Route path="/lounge" element={<Lounge />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/travel" element={<Travel />} />
      </Routes>  
  <Outlet/>
  <Footer/>
    
  </BrowserRouter>
  );
}

export default App;
